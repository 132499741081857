(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .controller("FlyerImportController", FlyerImportController);

    /** @ngInject */
    function FlyerImportController($scope, Upload, toastr, $translate, FlyersService, $interval, $window) {
        var vm = this;

        vm.file = null;
        vm.isLoading = false;
        vm.isUploading = false;
        vm.descriptonStep1 = $translate.instant("flyers.import.steps.step1.description2");
        vm.flyerAttributes = [
            "ean",
            "productReference",
            "name",
            "description",
            "gender",
            "brand",
            "category",
            "color",
            "size",
            "productPrice",
            "promotionalPrice",
            "qttPerPerson",
            "inventory",
            "height",
            "width",
            "length",
            "weight",
            "measureList",
            "imageUrl1",
            "imageUrl2",
            "imageUrl3",
            "imageUrl4",
            "demoVideo",
            "tags"
        ];

        vm.currentStep = 1;

        vm.gotoStep = function(step) {
            if (((step === 2 || step === 3) && !vm.file) || vm.isUploading) {
                return;
            }
            if (step === 3) {
                if (!requiredFieldsHaveBeenSelected()) {
                    return;
                }
                getPreview();
            }
            vm.currentStep = step;
        };

        vm.loadFile = function (file, errFiles) {
            vm.file = file;
            vm.errFiles = errFiles && errFiles[0];
            vm.isLoading = true;
            vm.importResult = null;
            vm.errorSummary = null;

            if (vm.errFiles) {
                vm.isLoading = false;
                if (errFiles[0].$error == "pattern") {
                    toastr.error($translate.instant("flyers.import.error.invalidType"));
                    return;
                }
            }

            if (file) {
                vm.currentStep = 2;

                var fileReader = new FileReader();
                fileReader.onload = function() {
                    this.arrayBuffer = fileReader.result;
                    var data = new Uint8Array(this.arrayBuffer);
                    var arr = new Array();
                    for (var i = 0; i != data.length; ++i)
                        arr[i] = String.fromCharCode(data[i]);
                    var bstr = arr.join("");
                    var workbook = XLSX.read(bstr, {
                        type: "binary", sheetRows: 4
                    });
                    var first_sheet_name = workbook.SheetNames[0];
                    var worksheet = workbook.Sheets[first_sheet_name];

                    vm.headers = getHeaderRow(worksheet);

                    var sheetsList = workbook.SheetNames;
                    vm.sheetData = XLSX.utils.sheet_to_json(
                        workbook.Sheets[sheetsList[0]],
                        {
                            header: 1,
                            defval: "",
                            blankrows: true
                        }
                    );

                    vm.isLoading = false;

                    $scope.$apply();
                };
                fileReader.readAsArrayBuffer(file);
            } else {
                vm.isLoading = false;
            }
        };

        function getHeaderRow(sheet) {
            var headers = [];
            var range = XLSX.utils.decode_range(sheet["!ref"]);
            var C,
                R = range.s.r; /* start in the first row */
            /* walk every column in the range */
            for (C = range.s.c; C <= range.e.c; ++C) {
                var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */
                var hdr = "UNKNOWN " + C; // <-- replace with your desired default
                if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

                headers.push({ column: numToSSColumn(C + 1), key: hdr, value: "NULL" });
            }
            return headers;
        }

        vm.uploadFile = function () {
            if (vm.file) {
                vm.isUploading = true;

                vm.file.upload = Upload.upload({
                    url: 'flyer/api/flyer/import-flyers-from-table?headers=' + getHeadersStringList(),
                    data: {
                        file: vm.file
                    }
                });

                vm.file.upload.then(function (response) {
                    if (response.data) {
                        // vm.file = null;

                        var interval = $interval(function () {
                            FlyersService.flyersImportStatus(response.data.id)
                                .then(function (response) {
                                    var data = JSON.parse(response.data)
                                    if (data.status === 'FINISHED') {
                                        $interval.cancel(interval);
                                        vm.isUploading = false;
                                        toastr.success($translate.instant('flyers.import.statusSuccessfulDescription'));
                                        vm.currentStep = 1;
                                        vm.file = null;
                                    } else if (data.status === 'IN_PROGRESS') {
                                        $interval.cancel(interval);
                                        vm.isUploading = false;
                                        toastr.success($translate.instant('flyers.import.successfullyImport'));
                                        vm.currentStep = 1;
                                        vm.file = null;
                                    } else if (data.status === 'FAILED') {
                                        $interval.cancel(interval);
                                        errorOnFileUploadOrSaveImport('flyers.import.error.errorOnSaveImport');
                                        vm.importResult = data;

                                        getErrorSummary();
                                    }
                                }, function (data) {
                                    $interval.cancel(interval);
                                    errorOnFileUploadOrSaveImport('flyers.import.error.errorOnSaveImport');
                                })
                                .catch(function (err) {
                                    $interval.cancel(interval);
                                    errorOnFileUploadOrSaveImport('flyers.import.error.errorOnSaveImport');
                                });
                        }, 5000);

                    } else {
                        errorOnFileUploadOrSaveImport('flyers.import.error.errorOnUploadFile');
                    }

                }, function (response) {
                    errorOnFileUploadOrSaveImport('flyers.import.error.errorOnUploadFile');
                });
            }
        }

        vm.cancel = function () {
            vm.file = null;
        }

        function errorOnFileUploadOrSaveImport(error) {
            toastr.error($translate.instant(error));
            vm.isUploading = false;
        }

        function getHeadersList() {
            return vm.headers.map(function (item) {
                return item.value;
            });
        }

        function getHeadersStringList() {
            return getHeadersList().join();
        }

        vm.getXlsxResult = function () {
            var fileExt = '.xlsx';
            var fileName = vm.importResult.id + fileExt;
            var header = vm.flyerAttributes.concat(["errors"]);

            var details = JSON.parse(JSON.stringify(vm.importResult.details));

            if (!details) {
                return;    
            }
            
            // translate errors
            details.forEach(function (product) {
                var errors = product.errors.map(function (error) {
                    return $translate.instant('flyers.import.error.' + error);
                }).join(' | ');
                product.errors = errors;
            });

            var workbook = XLSX.utils.book_new();
            var worksheet = XLSX.utils.json_to_sheet(details, { header: header });

            var range = XLSX.utils.decode_range(worksheet['!ref']);

            // translate header
            for (var C = range.s.c; C <= range.e.c; ++C) {
                var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
                if (!worksheet[address]) continue;
                worksheet[address].v = $translate.instant('flyers.import.column.' + worksheet[address].v);
            }

            var wscols = [];
            for (var i = 0; i < header.length; i++) {  // columns length added
                wscols.push({ wch: 15 })
            }
            wscols[wscols.length - 1] = { wch: 80 };
            worksheet["!cols"] = wscols;

            XLSX.utils.book_append_sheet(workbook, worksheet, 'tab1');
            XLSX.writeFile(workbook, fileName, { compression: true });
        }

        vm.changeHeaderValue = function(header) {
            vm.errorSummary = null;
            if (header.value === '') {
                header.value = 'NULL';
            } else {
                vm.headers.forEach(function (item) {
                    if (header.key !== item.key && header.value === item.value) {
                        header.value = 'NULL';
                        toastr.warning($translate.instant('flyers.import.error.repeatedColumn'));
                    } 
                });
            }
        }

        function numToSSColumn(num) {
            var s = '', t;

            while (num > 0) {
                t = (num - 1) % 26;
                s = String.fromCharCode(65 + t) + s;
                num = (num - t) / 26 | 0;
            }
            return s || undefined;
        }

        function getSelectedHeaders() {
            return vm.headers.filter(function (item) {
                return item.value !== 'NULL';
            });
        }

        function getPreview() {
            if (!vm.sheetData) {
                return;
            }
            var data = [];
            var headers = vm.headers.map(function (item) {
                return item.value !== 'NULL' ? $translate.instant('flyers.import.column.' + item.value) : item.value;
            });

            for (var i = 1; i < vm.sheetData.length; i++) {
                var result = [];
                for (var j = 0; j < headers.length; j++) {
                    var value = vm.sheetData[i][j];
                    if (headers[j] !== 'NULL') {
                        result.push(value);
                    }
                }
                data.push(result);
            }
            var headersPreview = headers.filter(function (header) {
                return header !== 'NULL'; 
            })

            vm.importPreview = {
                headers: headersPreview,
                data: data
            }
        }

       function getErrorSummary() {
            vm.errorSummary = [];

            if (vm.importResult && vm.importResult.details) {
                vm.importResult.details.forEach(function (product) {
                    product.errors.forEach(function (error) {
                        if (!vm.errorSummary.includes(error)) {
                            vm.errorSummary.push(error)
                        }
                    });
                });
            }
        }

        function requiredFieldsHaveBeenSelected() {
            var eanOrProductReference = false;
            var otherField = false;
            getSelectedHeaders().forEach(function (header) {
                if (header.value === "ean" || header.value === "productReference") {
                    eanOrProductReference = true;
                } else if (header.value !== "size") {
                    otherField = true;
                }
            });
            
            if (!eanOrProductReference) {
                toastr.warning($translate.instant('flyers.import.error.notAllowedNullableProductReferenceOrEan'));
            } else if (!otherField) {
                toastr.warning($translate.instant('flyers.import.error.noColumn'));
            }

            return eanOrProductReference && otherField;
        }

        vm.getDownloadLinkToTemplate = function () {
            return 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/template-import-products.xlsx';
        }
    }
})();
