/**
 *
 * @param {*} array - The array that you want extract the index that references the element
 * @param {*} attr - The attr that identify the element
 * @param {*} value - The value of attr passed
 */

function findIndexByAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

function getDomain(window) {
    if (window.location.hostname.includes('lojamalwee.online')) {
        return '312782249584097';
    } else if (window.location.hostname.includes('localhost')) {
        return '312782249584097';
    }
     else {
        return '1927510520841177';
    }
}

function formatMoney(value, currencySymbol, c, d, t) {
    var n = value,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0,
        cs = currencySymbol || '';
    return cs + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}

function getAllColorsToSearch() {
    return ['Amarelo', 'Azul', 'Bege', 'Bordô', 'Branco', 'Cinza', 'Dourado', 'Laranja', 'Lilás', 'Marrom', 'Prata', 'Preto', 'Rosa', 'Rose', 'Roxo', 'Salmão', 'Turquesa', 'Verde', 'Vermelho', 'Vinho',  'Violeta'];
}

function getAllColors() {
    return ['Amarelo', 'Azul', 'Azul Claro', 'Azul Escuro', 'Azul Marinho', 'Bege', 'Bordô', 'Branco', 'Cinza', 'Cinza Claro', 'Cinza Escuro', 'Dourado', 'Laranja', 'Lilás', 'Marrom', 'Marrom Claro', 'Prata', 'Preto', 'Rosa', 'Rosa Claro', 'Rosa Escuro', 'Rose', 'Roxo', 'Salmão', 'Turquesa', 'Verde', 'Verde Claro', 'Verde Escuro', 'Verde Musgo', 'Vermelho', 'Vinho'];
}

function getAllSizes() {
    var numberSizes = [];
    for(var i = 1; i <= 16; i++) {
        numberSizes.push(i.toString());
    }

    for(var j = 32; j <= 52; j+=2) {
        numberSizes.push(j.toString());
    }

    var literalSizes = ['U', 'PP', 'P', 'M', 'G', 'GG', 'XGG'];
    return numberSizes.concat(literalSizes);
}

function getClassicSizes() {
    return ['PP', 'P', 'P-M', 'M', 'G', 'GG', 'XG', 'U']
}

function getJuvenileChildSizes() {
    return ['RN', 'BB','1', '2','3', '4', '5', '6', '8', '10', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21',
        '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33'];
}

function getShortSizes() {
    return ['34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '46', '48', '50', '52', '54', '56'];
}

function getPlusSizes() {
    return ['G1','G2','G3','G4','G5','G+', 'XGGi', 'GI', 'M2', 'MI', 'P2', 'PI', 'XGG', 'XGI', 'EXG', 'EXX', 'GGI', 'XGGI', 'XP', '58', '60', '62', '68'];
}

function getOtherSizes() {
    return ['14-16', '15-18', '16-20', '17-18', '19-20', '19-22', '21-22', '21-25', '22-23', '22-25', '22-27', '23-24',
        '23-27', '24-25', '25-26', '26-30', '26-27', '26-29', '27-28', '28-31', '28-33', '29-30', '30-33', '31-32',
        '31-35', '32-35', '33-34', '34-36', '34-37', '34-39', '35-36', '35-39', '37-38', '37-39', '38-42', '39-40',
        '39-44', '40-44', '41-42', '42-45', '43-44'];
}

function getCurrencyListConfiguration() {
    return {
        LIBRA: {
            prefix: '£ ',
            centsSeparator: '.',
            thousandsSeparator: ',',
            key: 'LIBRA',
            text: 'Libra',
            decimalQuant: 2,
            mask: '#,##0.00',
            placeHolder: '0.00'
        },
        REAL: {
            prefix: 'R$ ',
            centsSeparator: ',',
            thousandsSeparator: '.',
            key: 'REAL',
            text: 'Real',
            decimalQuant: 2,
            mask: '#.##0,00',
            placeHolder: '0,00'
        },
        EURO: {
            prefix: '€ ',
            centsSeparator: ',',
            thousandsSeparator: '.',
            key: 'EURO',
            text: 'Euro',
            decimalQuant: 2,
            mask: '#.##0,00',
            placeHolder: '0,00'
        },
        DOLLAR: {
            prefix: '$ ',
            centsSeparator: '.',
            thousandsSeparator: ',',
            key: 'DOLLAR',
            text: 'Dólar',
            decimalQuant: 2,
            mask: '#,##0.00',
            placeHolder: '0.00'
        }

    };
}

/**
 *
 * @param {*} array - The array that you want extract the element
 * @param {*} attr - The attr that identify the element
 * @param {*} value - The value of attr passed
 */
function findElementByAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return array[i];
        }
    }
    return null;
}

function detectar_mobile() {
    var check = false; //wrapper no check
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}

function getDomainNameSufix(window, storeType) {
    var host = window.location.hostname;

    if (window.location.hostname.includes('.com.br')) {
        return /(\w+)(?=.com)/gm.exec(host)[0] + '.com.br';
    } else if (window.location.hostname.includes('.com')) {
        return /(\w+)(?=.com)/gm.exec(host)[0] + '.com';
    } else if (window.location.hostname.includes('.shop')) {
        return /(\w+)(?=.shop)/gm.exec(host)[0] + '.shop';
    } else if (window.location.hostname.includes('.digital')) {
        return /(\w+)(?=.digital)/gm.exec(host)[0] + '.digital';
	} else if (window.location.hostname.includes('.online')) {
        return /(\w+)(?=.online)/gm.exec(host)[0] + '.online';
	}
}

function sortAlphaNumIds(elem1, elem2) {
    if (elem1.id === elem2.id) {
        return 0;
    } else if (elem1.id < elem2.id) {
        return 1;
    } else {
        return -1;
    }
}

function sortAlphaNumElements(elem1, elem2) {
    if (elem1 === elem2) {
        return 0;
    } else if (elem1 < elem2) {
        return 1;
    } else {
        return -1;
    }
}

function sortDescFlyerByPurchaseValue(elem1, elem2) {
    if (elem1.purchaseValue === elem2.purchaseValue) {
        return 0;
    } else if (elem1.purchaseValue < elem2.purchaseValue) {
        return 1;
    } else {
        return -1;
    }
}

function removeElementFromArrayByAttr(array, attr, attrValue){
    var index = findIndexByAttr(array, attr, attrValue);
    if (index > -1){
        return array.splice(index, 1);
    }

    return array;
}


function getBanksBrasil() {
    return [
        {
            "code": "001",
            "pagarme": true,
            "label": "Banco do Brasil S.A."
        },
        {
            "code": "003",
            "label": "Banco da Amazônia S.A."
        },
        {
            "code": "004",
            "label": "Banco do Nordeste do Brasil S.A."
        },
        {
            "code": "007",
            "label": "Banco Nacional de Desenvolvimento Econômico e Social BNDES"
        },
        {
            "code": "010",
            "label": "Credicoamo Crédito Rural Cooperativa"
        },
        {
            "code": "011",
            "label": "Credit Suisse Hedging-Griffo Corretora de Valores S.A."
        },
        {
            "code": "012",
            "label": "Banco Inbursa S.A."
        },
        {
            "code": "014",
            "label": "Natixis Brasil S.A. Banco Múltiplo"
        },
        {
            "code": "015",
            "label": "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A."
        },
        {
            "code": "016",
            "label": "Coop de Créd. Mútuo dos Despachantes de Trânsito de SC e Rio Grande do Sul"
        },
        {
            "code": "017",
            "label": "BNY Mellon Banco S.A."
        },
        {
            "code": "018",
            "label": "Banco Tricury S.A."
        },
        {
            "code": "021",
            "label": "Banestes S.A. Banco do Estado do Espírito Santo"
        },
        {
            "code": "024",
            "label": "Banco Bandepe S.A."
        },
        {
            "code": "025",
            "label": "Banco Alfa S.A."
        },
        {
            "code": "029",
            "label": "Banco Itaú Consignado S.A."
        },
        {
            "code": "033",
            "pagarme": true,
            "label": "Banco Santander (Brasil) S. A."
        },
        {
            "code": "036",
            "label": "Banco Bradesco BBI S.A."
        },
        {
            "code": "037",
            "label": "Banco do Estado do Pará S.A."
        },
        {
            "code": "040",
            "label": "Banco Cargill S.A."
        },
        {
            "code": "041",
            "label": "Banco do Estado do Rio Grande do Sul S.A."
        },
        {
            "code": "047",
            "label": "Banco do Estado de Sergipe S.A."
        },
        {
            "code": "060",
            "label": "Confidence Corretora de Câmbio S.A."
        },
        {
            "code": "062",
            "label": "Hipercard Banco Múltiplo S.A."
        },
        {
            "code": "063",
            "label": "Banco Bradescard S.A."
        },
        {
            "code": "064",
            "label": "Goldman Sachs do Brasil  Banco Múltiplo S. A."
        },
        {
            "code": "065",
            "label": "Banco AndBank (Brasil) S.A."
        },
        {
            "code": "066",
            "label": "Banco Morgan Stanley S. A."
        },
        {
            "code": "069",
            "label": "Banco Crefisa S.A."
        },
        {
            "code": "070",
            "label": "Banco de Brasília S.A."
        },
        {
            "code": "074",
            "label": "Banco J. Safra S.A."
        },
        {
            "code": "075",
            "label": "Banco ABN Amro S.A."
        },
        {
            "code": "076",
            "label": "Banco KDB do Brasil S.A."
        },
        {
            "code": "077",
            "pagarme": true,
            "label": "Banco Inter S.A."
        },
        {
            "code": "078",
            "label": "Haitong Banco de Investimento do Brasil S.A."
        },
        {
            "code": "079",
            "label": "Banco Original do Agronegócio S.A."
        },
        {
            "code": "080",
            "label": "BT Corretora de Câmbio Ltda."
        },
        {
            "code": "081",
            "label": "BBN Banco Brasileiro de Negocios S.A."
        },
        {
            "code": "082",
            "label": "Banco Topazio S.A."
        },
        {
            "code": "083",
            "label": "Banco da China Brasil S.A."
        },
        {
            "code": "084",
            "label": "Uniprime Norte do Paraná - Cooperativa de Crédito Ltda."
        },
        {
            "code": "085",
            "label": "Cooperativa Central de Crédito Urbano - Cecred"
        },
        {
            "code": "088",
            "label": "Banco Random SA"
        },
        {
            "code": "089",
            "label": "Cooperativa de Crédito Rural da Região da Mogiana"
        },
        {
            "code": "091",
            "label": "Central de Cooperativas de Economia e Crédito Mútuo do Est RS - Unicred"
        },
        {
            "code": "092",
            "label": "BRK S.A. Crédito, Financiamento e Investimento"
        },
        {
            "code": "093",
            "label": "Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte"
        },
        {
            "code": "094",
            "label": "Banco Finaxis S.A."
        },
        {
            "code": "095",
            "label": "Banco Confidence de Câmbio S.A."
        },
        {
            "code": "096",
            "label": "Banco BMFBovespa de Serviços de Liquidação e Custódia S/A"
        },
        {
            "code": "097",
            "label": "Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi"
        },
        {
            "code": "098",
            "label": "Credialiança Cooperativa de Crédito Rural"
        },
        {
            "code": "099",
            "label": "Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda."
        },
        {
            "code": "100",
            "label": "Planner Corretora de Valores S.A."
        },
        {
            "code": "101",
            "label": "Renascença Distribuidora de Títulos e Valores Mobiliários Ltda."
        },
        {
            "code": "102",
            "label": "XP Investimentos Corretora de Câmbio Títulos e Valores Mobiliários S.A."
        },
        {
            "code": "104",
            "pagarme": true,
            "label": "Caixa Econômica Federal"
        },
        {
            "code": "105",
            "label": "Lecca Crédito, Financiamento e Investimento S/A"
        },
        {
            "code": "107",
            "label": "Banco Bocom BBM S.A."
        },
        {
            "code": "111",
            "label": "Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A."
        },
        {
            "code": "113",
            "label": "Magliano S.A. Corretora de Cambio e Valores Mobiliarios"
        },
        {
            "code": "117",
            "label": "Advanced Corretora de Câmbio Ltda."
        },
        {
            "code": "118",
            "label": "Standard Chartered Bank (Brasil) S.A. Banco de Investimento"
        },
        {
            "code": "119",
            "label": "Banco Western Union do Brasil S.A."
        },
        {
            "code": "120",
            "label": "Banco Rodobens SA"
        },
        {
            "code": "121",
            "label": "Banco Agibank S.A."
        },
        {
            "code": "122",
            "label": "Banco Bradesco BERJ S.A."
        },
        {
            "code": "124",
            "label": "Banco Woori Bank do Brasil S.A."
        },
        {
            "code": "125",
            "label": "Brasil Plural S.A. Banco Múltiplo"
        },
        {
            "code": "126",
            "label": "BR Partners Banco de Investimento S.A."
        },
        {
            "code": "127",
            "label": "Codepe Corretora de Valores e Câmbio S.A."
        },
        {
            "code": "128",
            "label": "MS Bank S.A. Banco de Câmbio"
        },
        {
            "code": "129",
            "label": "UBS Brasil Banco de Investimento S.A."
        },
        {
            "code": "130",
            "label": "Caruana S.A. Sociedade de Crédito, Financiamento e Investimento"
        },
        {
            "code": "131",
            "label": "Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda."
        },
        {
            "code": "132",
            "label": "ICBC do Brasil Banco Múltiplo S.A."
        },
        {
            "code": "133",
            "label": "Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar e"
        },
        {
            "code": "134",
            "label": "BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda."
        },
        {
            "code": "135",
            "label": "Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A."
        },
        {
            "code": "136",
            "label": "Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil"
        },
        {
            "code": "137",
            "label": "Multimoney Corretora de Câmbio Ltda"
        },
        {
            "code": "138",
            "label": "Get Money Corretora de Câmbio S.A."
        },
        {
            "code": "139",
            "label": "Intesa Sanpaolo Brasil S.A. - Banco Múltiplo"
        },
        {
            "code": "140",
            "label": "Easynvest - Título Corretora de Valores SA"
        },
        {
            "code": "141",
            "label": "Master BI SA"
        },
        {
            "code": "142",
            "label": "Broker Brasil Corretora de Câmbio Ltda."
        },
        {
            "code": "143",
            "label": "Treviso Corretora de Câmbio S.A."
        },
        {
            "code": "144",
            "label": "Bexs Banco de Câmbio S.A."
        },
        {
            "code": "145",
            "label": "Levycam - Corretora de Câmbio e Valores Ltda."
        },
        {
            "code": "146",
            "label": "Guitta Corretora de Câmbio Ltda."
        },
        {
            "code": "149",
            "label": "Facta Financeira S.A. - Crédito Financiamento e Investimento"
        },
        {
            "code": "157",
            "label": "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda."
        },
        {
            "code": "159",
            "label": "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor"
        },
        {
            "code": "163",
            "label": "Commerzbank Brasil S.A. - Banco Múltiplo"
        },
        {
            "code": "169",
            "label": "Banco Olé Bonsucesso Consignado S.A."
        },
        {
            "code": "172",
            "label": "Albatross Corretora de Câmbio e Valores S.A"
        },
        {
            "code": "173",
            "label": "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A."
        },
        {
            "code": "174",
            "label": "Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento"
        },
        {
            "code": "177",
            "label": "Guide Investimentos S.A. Corretora de Valores"
        },
        {
            "code": "180",
            "label": "CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda."
        },
        {
            "code": "182",
            "label": "Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento"
        },
        {
            "code": "183",
            "label": "Socred S.A. - Sociedade de Crédito ao Microempreendedor"
        },
        {
            "code": "184",
            "label": "Banco Itaú BBA S.A."
        },
        {
            "code": "188",
            "label": "Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores"
        },
        {
            "code": "189",
            "label": "HS Financeira S/A Crédito, Financiamento e Investimentos"
        },
        {
            "code": "190",
            "label": "Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio"
        },
        {
            "code": "191",
            "label": "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda."
        },
        {
            "code": "194",
            "label": "Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda."
        },
        {
            "code": "195",
            "label": "Valor SCD S.A."
        },
        {
            "code": "196",
            "label": "Fair Corretora de Câmbio S.A."
        },
        {
            "code": "197",
            "pagarme": true,
            "label": "Stone Pagamentos S.A."
        },
        {
            "code": "204",
            "label": "Banco Bradesco Cartões S.A."
        },
        {
            "code": "208",
            "pagarme": true,
            "label": "Banco BTG Pactual S.A."
        },
        {
            "code": "212",
            "label": "Banco Original S.A."
        },
        {
            "code": "213",
            "label": "Banco Arbi S.A."
        },
        {
            "code": "217",
            "label": "Banco John Deere S.A."
        },
        {
            "code": "218",
            "label": "Banco BS2 S.A."
        },
        {
            "code": "222",
            "label": "Banco Credit Agrícole Brasil S.A."
        },
        {
            "code": "224",
            "label": "Banco Fibra S.A."
        },
        {
            "code": "233",
            "label": "Banco Cifra S.A."
        },
        {
            "code": "237",
            "pagarme": true,
            "label": "Banco Bradesco S.A."
        },
        {
            "code": "241",
            "label": "Banco Clássico S.A."
        },
        {
            "code": "243",
            "label": "Banco Máxima S.A."
        },
        {
            "code": "246",
            "label": "Banco ABC Brasil S.A."
        },
        {
            "code": "249",
            "label": "Banco Investcred Unibanco S.A."
        },
        {
            "code": "250",
            "label": "BCV - Banco de Crédito e Varejo S/A"
        },
        {
            "code": "253",
            "label": "Bexs Corretora de Câmbio S/A"
        },
        {
            "code": "254",
            "label": "Parana Banco S. A."
        },
        {
            "code": "259",
            "label": "Moneycorp BCO de Cambio S.A."
        },
        {
            "code": "260",
            "pagarme": true,
            "label": "Nu Pagamentos S.A."
        },
        {
            "code": "265",
            "label": "Banco Fator S.A."
        },
        {
            "code": "266",
            "label": "Banco Cédula S.A."
        },
        {
            "code": "268",
            "label": "Barigui Companhia Hipotecária"
        },
        {
            "code": "269",
            "label": "HSBC Brasil S.A. Banco de Investimento"
        },
        {
            "code": "271",
            "label": "IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda."
        },
        {
            "code": "272",
            "label": "AGK CC S.A."
        },
        {
            "code": "273",
            "label": "CCR de São Miguel do Oeste"
        },
        {
            "code": "274",
            "label": "BMP SCMEPP LTDA"
        },
        {
            "code": "276",
            "label": "BCO SENFF S.A."
        },
        {
            "code": "278",
            "label": "Genial Investimentos CVM S.A."
        },
        {
            "code": "279",
            "label": "COOP de Primavera do Leste"
        },
        {
            "code": "280",
            "label": "Will Financeira SACFI"
        },
        {
            "code": "281",
            "label": "CCR COOPAVEL"
        },
        {
            "code": "283",
            "label": "RB Investimentos DTVM LTDA"
        },
        {
            "code": "285",
            "label": "Frente CC S.A."
        },
        {
            "code": "288",
            "label": "Carol DTVM LTDA"
        },
        {
            "code": "289",
            "label": "EFX CC LTDA"
        },
        {
            "code": "290",
            "label": "PAGSEGURO Internet IP S.A."
        },
        {
            "code": "292",
            "label": "Galapagos DTVM S.A."
        },
        {
            "code": "293",
            "label": "Lastro RDV DTVM LTDA"
        },
        {
            "code": "296",
            "label": "OZ Corretora de Cambio S.A."
        },
        {
            "code": "298",
            "label": "VIPS CC LTDA"
        },
        {
            "code": "299",
            "label": "BCO AFINZ S.A. - BM"
        },
        {
            "code": "300",
            "label": "Banco de la Nacion Argentina"
        },
        {
            "code": "301",
            "label": "DOCK IP S.A."
        },
        {
            "code": "305",
            "label": "Fourtrade Cor de Cambio LTDA"
        },
        {
            "code": "306",
            "label": "Portopar DTVM LTDA"
        },
        {
            "code": "307",
            "label": "Terra Investimentos DTVM"
        },
        {
            "code": "309",
            "label": "Cambionet CC LTDA"
        },
        {
            "code": "310",
            "label": "VORTX DTVM LTDA"
        },
        {
            "code": "312",
            "label": "HSCM SCMEPP LTDA"
        },
        {
            "code": "318",
            "label": "Banco BMG S.A."
        },
        {
            "code": "319",
            "label": "OM DTVM LTDA"
        },
        {
            "code": "320",
            "label": "China Construction Bank (Brasil) Banco Múltiplo S/A"
        },
        {
            "code": "321",
            "label": "Crefaz SCMEPP LTDA"
        },
        {
            "code": "322",
            "label": "CCR de Abelardo Luz"
        },
        {
            "code": "323",
            "label": "Mercado Pago IP LTDA"
        },
        {
            "code": "324",
            "label": "Cartos SCD S.A."
        },
        {
            "code": "325",
            "label": "ORAMA DTVM S.A."
        },
        {
            "code": "326",
            "label": "Parati - CFI S.A."
        },
        {
            "code": "328",
            "label": "CECM Fabric Calçados Sapiranga"
        },
        {
            "code": "329",
            "label": "QI SCD S.A."
        },
        {
            "code": "330",
            "label": "Banco Bari S.A."
        },
        {
            "code": "331",
            "label": "Fram Capital DTVM S.A."
        },
        {
            "code": "332",
            "label": "Acesso Soluções de Pagamento S.A. - IP"
        },
        {
            "code": "334",
            "label": "Banco Besa S.A."
        },
        {
            "code": "335",
            "label": "Banco Digio"
        },
        {
            "code": "336",
            "pagarme": true,
            "label": "Banco C6"
        },
        {
            "code": "340",
            "label": "Superdigital IP S.A."
        },
        {
            "code": "341",
            "pagarme": true,
            "label": "Itaú Unibanco  S.A."
        },
        {
            "code": "342",
            "label": "Creditas SCD"
        },
        {
            "code": "343",
            "label": "FFA SCMEPP LTDA"
        },
        {
            "code": "348",
            "label": "BCO XP S.A."
        },
        {
            "code": "349",
            "label": "AL5 S.A. CFI"
        },
        {
            "code": "350",
            "label": "Crehnor Laranjeiras"
        },
        {
            "code": "352",
            "label": "Toro CTVM S.A."
        },
        {
            "code": "355",
            "label": "Otimo SCD S.A."
        },
        {
            "code": "358",
            "label": "Midway S.A. - SCFI"
        },
        {
            "code": "359",
            "label": "Zema CFI S.A."
        },
        {
            "code": "360",
            "label": "Trinus Capital DTVM"
        },
        {
            "code": "362",
            "label": "CIELO IP S.A."
        },
        {
            "code": "363",
            "label": "Singulare CTVM S.A."
        },
        {
            "code": "364",
            "label": "EFI S.A. - IP"
        },
        {
            "code": "365",
            "label": "SIMPAUL"
        },
        {
            "code": "366",
            "label": "Banco Société Générale Brasil S.A."
        },
        {
            "code": "367",
            "label": "BCO Societe Generale Brasil"
        },
        {
            "code": "368",
            "label": "Vitreo DTVM S.A."
        },
        {
            "code": "370",
            "label": "Banco Mizuho do Brasil S.A."
        },
        {
            "code": "371",
            "label": "Warren CVMC LTDA"
        },
        {
            "code": "373",
            "label": "UPP SEP S.A."
        },
        {
            "code": "374",
            "label": "Realize CFI S.A."
        },
        {
            "code": "376",
            "label": "Banco J. P. Morgan S. A."
        },
        {
            "code": "377",
            "label": "BMS SCD S.A."
        },
        {
            "code": "378",
            "label": "BCO Brasileiro de Crédito S.A."
        },
        {
            "code": "379",
            "label": "CECM Cooperforte"
        },
        {
            "code": "380",
            "label": "PicPay"
        },
        {
            "code": "381",
            "label": "BCO Mercedes Benz S.A."
        },
        {
            "code": "382",
            "label": "Fiducia SCMEPP LTDA"
        },
        {
            "code": "383",
            "label": "Ebanx IP LTDA"
        },
        {
            "code": "384",
            "label": "Global SCM LTDA"
        },
        {
            "code": "385",
            "label": "CECM dos Trabport da GVitor"
        },
        {
            "code": "386",
            "label": "Nu Financeira S.A. CFI"
        },
        {
            "code": "387",
            "label": "BCO Toyota do Brasil S.A."
        },
        {
            "code": "389",
            "label": "Banco Mercantil do Brasil S.A."
        },
        {
            "code": "390",
            "label": "BCO GM S.A."
        },
        {
            "code": "391",
            "label": "CCR de Ibiam"
        },
        {
            "code": "393",
            "label": "BCO Bradesco Financ. S.A."
        },
        {
            "code": "394",
            "label": "Banco Bradesco Financiamentos S.A."
        },
        {
            "code": "395",
            "label": "F D Gold DTVM LTDA"
        },
        {
            "code": "396",
            "label": "HUB IP S.A."
        },
        {
            "code": "397",
            "label": "Listo SCD S.A."
        },
        {
            "code": "398",
            "label": "Ideal CTVM S.A."
        },
        {
            "code": "399",
            "label": "Kirton Bank S.A. - Banco Múltiplo"
        },
        {
            "code": "400",
            "label": "COOP Creditag"
        },
        {
            "code": "401",
            "label": "IUGU IP S.A."
        },
        {
            "code": "402",
            "label": "Cobuccio S.A. SCFI"
        },
        {
            "code": "403",
            "label": "CORA SCD S.A."
        },
        {
            "code": "404",
            "label": "SUMUP SCD S.A."
        },
        {
            "code": "406",
            "label": "Accredito SCD S.A."
        },
        {
            "code": "407",
            "label": "Indigo Investimentos DTVM LTDA"
        },
        {
            "code": "408",
            "label": "Bonuspago SCD S.A."
        },
        {
            "code": "410",
            "label": "Planner Sociedade de Credito Direto"
        },
        {
            "code": "412",
            "label": "Banco Capital S. A."
        },
        {
            "code": "413",
            "label": "BCO BV S.A."
        },
        {
            "code": "414",
            "label": "LEND SCD S.A."
        },
        {
            "code": "416",
            "label": "Lamara SCD S.A."
        },
        {
            "code": "418",
            "label": "Zipdin SCD S.A."
        },
        {
            "code": "419",
            "label": "Numbrs SCD S.A."
        },
        {
            "code": "421",
            "label": "CC Lar Credi"
        },
        {
            "code": "422",
            "pagarme": true,
            "label": "Banco Safra S.A"
        },
        {
            "code": "426",
            "label": "Neon Financeira CFI S.A"
        },
        {
            "code": "427",
            "label": "CRED-UFES"
        },
        {
            "code": "428",
            "label": "Credsystem SCD S.A."
        },
        {
            "code": "429",
            "label": "Crediare CFI S.A."
        },
        {
            "code": "430",
            "label": "CCR Seara"
        },
        {
            "code": "433",
            "label": "BR-Capital DTVM S.A."
        },
        {
            "code": "435",
            "label": "Delcred SCD S.A."
        },
        {
            "code": "438",
            "label": "Trustee DTVM LTDA."
        },
        {
            "code": "439",
            "label": "ID CTVM"
        },
        {
            "code": "440",
            "label": "Credibrf Coop"
        },
        {
            "code": "443",
            "label": "Credihome SCD"
        },
        {
            "code": "444",
            "label": "Trinus SCD S.A."
        },
        {
            "code": "445",
            "label": "Platae CFI"
        },
        {
            "code": "447",
            "label": "Mirae Asset CCTVM LTDA"
        },
        {
            "code": "448",
            "label": "Hermera DTVM LTDA"
        },
        {
            "code": "449",
            "label": "DM"
        },
        {
            "code": "450",
            "label": "Fitbank IP"
        },
        {
            "code": "451",
            "label": "J17 - SCD S.A."
        },
        {
            "code": "452",
            "label": "Credifit SCD S.A."
        },
        {
            "code": "454",
            "label": "Merito DTVM LTDA"
        },
        {
            "code": "455",
            "label": "Fenix DTVM LTDA"
        },
        {
            "code": "456",
            "label": "Banco MUFG Brasil S.A."
        },
        {
            "code": "457",
            "label": "UY3 SCD S.A."
        },
        {
            "code": "458",
            "label": "Hedge Investiments DTVM LTDA"
        },
        {
            "code": "460",
            "label": "Unavanti SCD S.A."
        },
        {
            "code": "461",
            "label": "Asaas IP S.A."
        },
        {
            "code": "462",
            "label": "Stark SCD S.A."
        },
        {
            "code": "463",
            "label": "Azumi DTVM"
        },
        {
            "code": "464",
            "label": "Banco Sumitomo Mitsui Brasileiro S.A."
        },
        {
            "code": "465",
            "label": "Capital Consig SCD S.A."
        },
        {
            "code": "467",
            "label": "Master S.A. CCTVM"
        },
        {
            "code": "468",
            "label": "Portoseg S.A. CFI"
        },
        {
            "code": "469",
            "label": "Picpay Invest"
        },
        {
            "code": "470",
            "label": "CDC SCD S.A."
        },
        {
            "code": "471",
            "label": "CECM Serv Punl Pinhao"
        },
        {
            "code": "473",
            "label": "Banco Caixa Geral - Brasil S.A."
        },
        {
            "code": "475",
            "label": "BCO Yamaha Motor S.A."
        },
        {
            "code": "477",
            "label": "Citibank N.A."
        },
        {
            "code": "478",
            "label": "Gazincred S.A. SCFI"
        },
        {
            "code": "479",
            "label": "Banco ItauBank S.A."
        },
        {
            "code": "481",
            "label": "Superlogica SCD S.A."
        },
        {
            "code": "482",
            "label": "SBCash SCD"
        },
        {
            "code": "484",
            "label": "MAF DTVM S.A."
        },
        {
            "code": "487",
            "label": "Deutsche Bank S.A. - Banco Alemão"
        },
        {
            "code": "488",
            "label": "JPMorgan Chase Bank, National Association"
        },
        {
            "code": "492",
            "label": "ING Bank N.V."
        },
        {
            "code": "494",
            "label": "Banco de La Republica Oriental del Uruguay"
        },
        {
            "code": "495",
            "label": "Banco de La Provincia de Buenos Aires"
        },
        {
            "code": "505",
            "label": "Banco Credit Suisse (Brasil) S.A."
        },
        {
            "code": "506",
            "label": "RJI"
        },
        {
            "code": "507",
            "label": "SCFI EFI S.A."
        },
        {
            "code": "508",
            "label": "Avenue Securities DTVM LTDA"
        },
        { "label": "CELCOIN IP SA", "code": "509" },
        { "label": "FFCRED SCD SA", "code": "510" },
        { "label": "MAGNUM SCD", "code": "511" },
        { "label": "FINVEST DTVM", "code": "512" },
        { "label": "ATF CREDIT SCD SA", "code": "513" },
        { "label": "EXIM CC LTDA", "code": "514" },
        { "label": "QISTA SA CFI", "code": "516" },
        { "label": "MERCADO CREDITO SCFI SA", "code": "518" },
        { "label": "LIONS TRUST DTVM", "code": "519" },
        { "label": "SOMAPAY SCD SA", "code": "520" },
        { "label": "PEAK SEP SA", "code": "521" },
        { "label": "RED SCD SA", "code": "522" },
        { "label": "HR DIGITAL SCD", "code": "523" },
        { "label": "WNT CAPITAL DTVM", "code": "524" },
        { "label": "INTERCAM CC LTDA", "code": "525" },
        { "label": "MONETARIE SCD", "code": "526" },
        { "label": "ATICCA SCD SA", "code": "527" },
        { "label": "REAG DTVM SA", "code": "528" },
        { "label": "PINBANK IP", "code": "529" },
        { "label": "SER FINANCE SCD SA", "code": "530" },
        { "label": "BMP SCD SA", "code": "531" },
        { "label": "EAGLE SCD SA", "code": "532" },
        { "label": "M18 IP SA", "code": "533" },
        { "label": "EWALLY IP SA", "code": "534" },
        { "label": "OPEA SCD", "code": "535" },
        { "label": "NEON PAGAMENTOS SA IP", "code": "536" },
        { "label": "MICROCASH SCMEPP LTDA", "code": "537" },
        { "label": "SUDACRED SCMEPP LTDA", "code": "538" },
        { "label": "SANTINVEST SA CFI", "code": "539" },
        { "label": "FACILICRED SCM LTDA", "code": "540" },
        { "label": "FDO GARANTIDOR CRÉDITOS", "code": "541" },
        { "label": "CLOUDWALK IP LTDA", "code": "542" },
        { "label": "COOPCRECE", "code": "543" },
        { "label": "MULTICRED SCD SA", "code": "544" },
        {
            "code": "545",
            "label": "Senso Corretora de Câmbio e Valores Mobiliários S.A."
        },
        { "code": "546", "label": "U4C INSTITUICAO DE PAGAMENTO SA" },
        { "code": "547", "label": "BNK DIGITAL SCD SA" },
        { "code": "548", "label": "RPW SA SCFI" },
        { "code": "549", "label": "INTRA DTVM" },
        { "code": "550", "label": "BEETELLER" },
        { "code": "552", "label": "UZZIPAY IP SA" },
        { "code": "553", "label": "PERCAPITAL SCD SA" },
        { "code": "554", "label": "STONEX BANCO DE CAMBIO SA" },
        { "code": "555", "label": "PAN CFI" },
        { "code": "556", "label": "PROSEFTUR" },
        { "code": "557", "label": "PAGPRIME IP" },
        { "code": "558", "label": "QI DTVM LTDA" },
        { "code": "559", "label": "KANASTRA SCD" },
        { "code": "560", "label": "MAG IP LTDA" },
        { "code": "561", "label": "PAY4FUN IP SA" },
        { "code": "562", "label": "AZIMUT BRASIL DTVM LTDA" },
        { "code": "563", "label": "PROTEGE PAY CASH IP SA" },
        { "code": "565", "label": "AGORA CTVM SA" },
        { "code": "566", "label": "FLAGSHIP IP LTDA" },
        { "code": "567", "label": "CREDITAQUI FINANCEIRA SA - CFI" },
        { "code": "568", "label": "BRCONDOS SCD SA" },
        { "code": "569", "label": "CONTA PRONTA IP" },
        { "code": "572", "label": "ALL IN CRED SCD SA" },
        { "code": "577", "label": "AF DESENVOLVE SP SA" },
        { "code": "579", "label": "QUADRA SCD" },
        { "code": "580", "label": "CCCPOUPINV SUL E SUDESTE CENTRAL SICREDI SULSUDESTE" },
        { "code": "582", "label": "CCC POUP INV DE MS GO DF E TO" },
        { "code": "583", "label": "CCC POUP INV DO CENTRO NORTE DO BRASIL" },
        { "code": "584", "label": "CCC POUP E INV DOS ESTADOS DO PR SP E RJ" },
        { "code": "586", "label": "Z1 IP LTDA" },
        { "code": "588", "label": "PROVER PROMOCAO DE VENDAS IP LTDA" },
        { "code": "590", "label": "REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS IP SA" },
        { "code": "594", "label": "EMBRACRED SA SCD" },
        { "code": "597", "label": "ISSUER IP LTDA" },
        { "code": "600", "label": "BCO LUSO BRASILEIRO S.A." },
        {
            "code": "600",
            "label": "Banco Luso Brasileiro S.A."
        },
        {
            "code": "604",
            "label": "Banco Industrial do Brasil S.A."
        },
        {
            "code": "610",
            "label": "Banco VR S.A."
        },
        {
            "code": "611",
            "label": "Banco Paulista S.A."
        },
        {
            "code": "612",
            "label": "Banco Guanabara S.A."
        },
        {
            "code": "613",
            "label": "Omni Banco S.A."
        },
        {
            "code": "623",
            "label": "Banco Pan S.A."
        },
        {
            "code": "626",
            "label": "Banco Ficsa S. A."
        },
        {
            "code": "630",
            "label": "Banco Intercap S.A."
        },
        {
            "code": "633",
            "label": "Banco Rendimento S.A."
        },
        {
            "code": "634",
            "label": "Banco Triângulo S.A."
        },
        {
            "code": "637",
            "label": "Banco Sofisa S. A."
        },
        {
            "code": "641",
            "label": "Banco Alvorada S.A."
        },
        {
            "code": "643",
            "label": "Banco Pine S.A."
        },
        {
            "code": "652",
            "label": "Itaú Unibanco Holding S.A."
        },
        {
            "code": "653",
            "label": "Banco Indusval S. A."
        },
        {
            "code": "654",
            "label": "Banco A. J. Renner S.A."
        },
        {
            "code": "655",
            "label": "Banco Votorantim S.A."
        },
        {
            "code": "707",
            "label": "Banco Daycoval S.A."
        },
        {
            "code": "712",
            "label": "Banco Ourinvest S.A."
        },
        {
            "code": "719",
            "label": "Banif - Bco Internacional do Funchal (Brasil) S.A."
        },
        {
            "code": "720",
            "label": "BCO RNX S.A."
        },
        {
            "code": "735",
            "label": "Banco Neon S.A."
        },
        {
            "code": "739",
            "label": "Banco Cetelem S.A."
        },
        {
            "code": "741",
            "label": "Banco Ribeirão Preto S.A."
        },
        {
            "code": "743",
            "label": "Banco Semear S.A."
        },
        {
            "code": "745",
            "label": "Banco Citibank S.A."
        },
        {
            "code": "746",
            "label": "Banco Modal S.A."
        },
        {
            "code": "747",
            "label": "Banco Rabobank International Brasil S.A."
        },
        {
            "code": "748",
            "label": "Banco Cooperativo Sicredi S. A."
        },
        {
            "code": "751",
            "label": "Scotiabank Brasil S.A. Banco Múltiplo"
        },
        {
            "code": "752",
            "label": "Banco BNP Paribas Brasil S.A."
        },
        {
            "code": "753",
            "label": "Novo Banco Continental S.A. - Banco Múltiplo"
        },
        {
            "code": "754",
            "label": "Banco Sistema S.A."
        },
        {
            "code": "755",
            "label": "Bank of America Merrill Lynch Banco Múltiplo S.A."
        },
        {
            "code": "756",
            "label": "Banco Cooperativo do Brasil S/A - Bancoob"
        },
        {
            "code": "757",
            "label": "Banco Keb Hana do Brasil S. A."
        }
    ]
}

function getAllStates() {
    return [
        { uf : 'AC', name: 'Acre' },
        { uf : 'AL', name: 'Alagoas' },
        { uf : 'AP', name: 'Amapá' },
        { uf : 'AM', name: 'Amazonas' },
        { uf : 'BA', name: 'Bahia' },
        { uf : 'CE', name: 'Ceará' },
        { uf : 'DF', name: 'Distrito Federal' },
        { uf : 'ES', name: 'Espírito Santo' },
        { uf : 'GO', name: 'Goías' },
        { uf : 'MA', name: 'Maranhão' },
        { uf : 'MT', name: 'Mato Grosso' },
        { uf : 'MS', name: 'Mato Grosso do Sul' },
        { uf : 'MG', name: 'Minas Gerais' },
        { uf : 'PA', name: 'Pará' },
        { uf : 'PB', name: 'Paraíba' },
        { uf : 'PR', name: 'Paraná' },
        { uf : 'PE', name: 'Pernambuco' },
        { uf : 'PI', name: 'Piauí' },
        { uf : 'RJ', name: 'Rio de Janeiro' },
        { uf : 'RN', name: 'Rio Grande do Norte' },
        { uf : 'RS', name: 'Rio Grande do Sul' },
        { uf : 'RO', name: 'Rondônia' },
        { uf : 'RR', name: 'Roraíma' },
        { uf : 'SC', name: 'Santa Catarina' },
        { uf : 'SP', name: 'São Paulo' },
        { uf : 'SE', name: 'Sergipe' },
        { uf : 'TO', name: 'Tocantins' }
    ];
}

/**
 * Sort a list by attribute
 */
function sortListByStrAttribute(list, attribute) {
    return list.sort(function(a, b) {
        if (a[attribute].toLowerCase() < b[attribute].toLowerCase()) {
            return -1;
        }
        if (a[attribute].toLowerCase() > b[attribute].toLowerCase()) {
            return 1;
        }
        return 0;
    });
}

function isNullOrUndefined(object) {
    return object === null || object === undefined;
}

function isNullEmptyOrUndefined(object) {
    return object === null || object === undefined || object.length === 0;
}
